// Commonly used web colors
$black: #000000; // Black
$white: #ffffff; // White
$red-600: #b7253d !default; // Deep Carmine
$red-500: #df405a !default; // Cerise
$grey-600: #4e4c5a; // Trout
$grey-100: #dadaf3; // Topaz

$success-green: #79bd9a !default; // Padua
$error-red: $red-500 !default; // Cerise
$warning-red: #ff5050 !default; // Sunset Orange
$gold-star: #ca8f04 !default; // Dark Goldenrod

$red-bookmark: $warning-red;

// Values from the classic Mastodon UI
$classic-base-color: #282c37; // Midnight Express
$classic-primary-color: #9baec8; // Echo Blue
$classic-secondary-color: #d9e1e8; // Pattens Blue
$classic-highlight-color: #6364ff; // Brand purple

// Kiara's espresso palette
$bg-black: #000000;
$bg-darker: #21140c;
$bg-dark: #34251b;
// Src: https://www.color-hex.com/color-palette/24950
$bg-highlight: #4f3828; // Substitutes ligthten()ed $bg-dark.
$highlight: #e5ab14; // TODO: Kiara's original is too light.
//$highlight: #ca8f04;
$action: #d9a982;
$action-soft: #dfc29f;
$text-dim: #9c856a;

// Variables for defaults in UI
$base-shadow-color: $black !default;
$base-overlay-background: $black !default;
$base-border-color: $white !default;
$simple-background-color: $bg-dark !default; // Emoji picker, textareas, inputs, etc.
$valid-value-color: $success-green !default;
$error-value-color: $error-red !default;

// Tell UI to use selected colors
$ui-base-color: $bg-darker !default;
$ui-base-lighter-color: $bg-dark !default;

$ui-primary-color: $action !default;
$ui-secondary-color: $action-soft !default;
$ui-highlight-color: $highlight !default;

$ui-button-color: $bg-darker !default;
$ui-button-background-color: $highlight !default;
$ui-button-focus-background-color: darken($highlight, 7%) !default;
$ui-button-focus-outline-color: darken($highlight, 7%) !default;
$ui-button-focus-outline: solid 2px $ui-button-focus-outline-color !default;

$ui-button-secondary-color: $action !default;
$ui-button-secondary-border-color: $action !default;
$ui-button-secondary-focus-background-color: $action !default;
$ui-button-secondary-focus-color: $bg-darker !default;

$ui-button-tertiary-color: $action-soft !default;
$ui-button-tertiary-border-color: $action-soft !default;
$ui-button-tertiary-focus-background-color: $action-soft !default;
$ui-button-tertiary-focus-color: $bg-darker !default;

$ui-button-destructive-background-color: $red-500 !default;
$ui-button-destructive-focus-background-color: $red-600 !default;

$ui-button-icon-focus-outline: $ui-button-focus-outline !default;
$ui-button-icon-hover-background-color: rgba(140, 141, 255, 40%) !default;

// Variables for texts
$primary-text-color: $white !default;
$darker-text-color: $ui-primary-color !default;
$dark-text-color: $text-dim !default; // Muted text, notifications.
$secondary-text-color: $ui-secondary-color !default;
$highlight-text-color: lighten($ui-highlight-color, 8%) !default;
$action-button-color: $action !default;
$action-button-focus-color: lighten($ui-base-lighter-color, 4%) !default;
$passive-text-color: $highlight !default;
$active-passive-text-color: $highlight !default;

// For texts on inverted backgrounds
// We pretty much force all inverted areas to not be inverted anymore, so we set inverted text colors
// to be the same as non-inverted ones.
$inverted-text-color: $white !default; // Text on "replying to" window, emoji picker and boost modal.
$light-text-color: $action !default; // Darker text in modal.
$lighter-text-color: $action-soft !default; // Some buttons in compose box.

// Language codes that uses CJK fonts
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;

// Variables for components
$media-modal-media-max-width: 100%;

// put margins on top and bottom of image to avoid the screen covered by image.
$media-modal-media-max-height: 80%;

$no-gap-breakpoint: 1175px;

$font-sans-serif: 'mastodon-font-sans-serif' !default;
$font-display: 'mastodon-font-display' !default;
$font-monospace: 'mastodon-font-monospace' !default;
